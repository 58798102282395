
import Btn from "@/components/Btn.vue";
import Card from "@/components/Card.vue";
import Field from "@/components/Field.vue";
import LangSelect from "@/components/LangSelect.vue";
import Logo from "@/components/Logo.vue";
import validator from "validator";
import { Component, Vue } from "vue-property-decorator";
import { Action, Mutation } from "vuex-class";
import "../assets/images/logo.svg";
import { RoutesNames } from "../router";

@Component({
  components: {
    Field,
    Card,
    Btn,
    LangSelect,
    Logo,
  },
})
export default class LoginView extends Vue {
  email = "";
  password = "";
  showPassword = false;
  isFormValid = false;
  loading = false;

  @Mutation setUser;
  @Mutation("login") setUserAsLoggedIn;
  @Mutation showError;
  @Action login;

  emailRules = [
    (v) => !!v || "Inserire la mail",
    (v) => !!validator.isEmail(v ? v : "") || "Email non valida",
  ];
  passwordRules = [(v) => !!v || "Inserire la password"];

  async mounted() {
    this.$store.commit("setFillHeightOnMainContainer", true);
    this.resetValues();
    if (this.$route.query.email) {
      this.email = this.$route.query.email as string;
    }
  }

  resetValues() {
    (this.$refs.loginForm as Vue & { reset: () => void })?.reset();
  }

  async signIn() {
    this.loading = true;
    if (
      (this.$refs.loginForm as Vue & {
        validate: () => boolean;
      }).validate()
    ) {
      await this.login({
        email: this.email,
        password: this.password,
      });
      this.loading = false;
    }
  }

  getCopy() {
    return (
      "© " +
      new Date().getFullYear() +
      " CNA™ - " +
      this.$t("allRightsReserved")
    );
  }

  getCCIAALogo() {
    return require("../assets/images/logo_CCIAA_white.png");
  }

  goToForgotPassword() {
    this.$router.push({ name: RoutesNames.forgotPassword });
  }

  goToSignup(){
    this.$router.push({ name: RoutesNames.signup });
  }
}
